import { FETCH, SET, FETCH_SUCCESS, 
  FETCH_FAILURE, SET_USER, 
  SET_IS_AUTHENTICATED,
SET_REDIRECTED_LOGIN,
DISABLED_REDIRECT_LOGIN } from './actionTypes';
import { API, Auth } from 'aws-amplify';    

export function setAuthenticated(payload: any) {
  return {
    type: 'SET_IS_AUTHENTICATED',
    payload
  }
}
export async function checkIfIsAuthenticated(dispatch:any){
  console.log("Hemos ingresado a validar si esta authenticado");
  // const {authenticated} = await  Auth.currentUserCredentials();
  try{
    let {attributes} = await Auth.currentAuthenticatedUser()
  console.log("attributes",attributes)
  if(attributes){
    console.log("attributes", attributes)
    let cod_roltercero =attributes['custom:codroltercero'];
    const resultadoTercero = await API.post(process.env.REACT_APP_ENDPOINT_NAME, `/tercero/link/obtener-datos`, {
      body: {
        "request":{
          "payload":{
            "cod_roltercero": cod_roltercero	
          }
        }
      }
    })
    console.log("resultadoTercero", resultadoTercero)
    dispatch({
      type: SET_USER,
      payload: resultadoTercero
    } )
    return true
  }


  }catch(e){
      dispatch({
        type: SET_IS_AUTHENTICATED,
        payload: false
      })
      return false
  }
}

export function setUserInfo(userInfo: any){
  return {
    type: SET_USER,
    payload: userInfo
  }

}

export function set(payload: any) {
  return {
    type: SET,
    payload
  }
}

export function fetch(payload: any) {
  return {
    type: FETCH,
    payload
  }
}

export function fetchSuccess(payload?: any) {
  return {
    type: FETCH_SUCCESS,
    payload
  }
}

export function fetchFail(payload: any) {
  return {
    type: FETCH_FAILURE,
    payload
  }
}

export function setRedirectLogin(payload: any) {
  return {
    type: SET_REDIRECTED_LOGIN,
    payload
  }
}

export function disabledRedirectLogin(){
  return {
    type: DISABLED_REDIRECT_LOGIN
  }
}

